/*
 *    Copyright [2021] [wisemapping]
 *
 *   Licensed under WiseMapping Public License, Version 1.0 (the "License").
 *   It is basically the Apache License, Version 2.0 (the "License") plus the
 *   "powered by wisemapping" text requirement on every single page;
 *   you may not use this file except in compliance with the License.
 *   You may obtain a copy of the license at
 *
 *       http://www.wisemapping.org/license
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
import React, { useContext, useEffect, useState } from 'react';
import { ClientContext } from '../../classes/provider/client-context';
import SessionExpiredDialog from './session-expired-dialog';
import { Outlet } from 'react-router-dom';

const CommonPage = (): React.ReactElement => {
  const [sessionExpired, setSessionExpired] = useState(false);
  const client = useContext(ClientContext);

  useEffect(() => {
    client.onSessionExpired(() => {
      setSessionExpired(true);
    });
  }, [client]);

  return (
    <>
      <SessionExpiredDialog open={sessionExpired} />
      <Outlet />
    </>
  );
};

export default CommonPage;
